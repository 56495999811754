import React from 'react';
import { CardShelf, Footer, Header, Search, InfoShelf } from '../components';

export const HomePage = () => {
  return (
    <>
      <Header />
      <Search />
      <CardShelf />
      <InfoShelf />
      {/* <CustomHomepageGrid>
      <HomePageRandomJoke />
      <MicrosoftCalendarCard />
      <HomePageStarredEntities />
    </CustomHomepageGrid>*/}
      <Footer />
    </>
  );
};
